$ka-background-color: #2b2b2b;
$ka-border-color: #151619;
$ka-cell-hover-background-color: transparentize(#fff, 0.8);
$ka-color-base: #fefefe;
$ka-input-background-color: $ka-background-color;
$ka-input-border-color: $ka-border-color;
$ka-input-color: $ka-color-base;
$ka-row-hover-background-color: transparentize(#fff, 0.9);
$ka-thead-background-color: #151619;
$ka-thead-color: #c5c5c5;
